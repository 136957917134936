import {
    useGetDataQuery,
    useUpdateResourceMutation,
} from "../../api/astrosApi";
import { DataTableControl } from "../../components/adminzone/dataTableControl";
import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    Select,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { MyMap } from "../../components";
import { useEffect, useState } from "react";

export const AdminSensorsPage = () => {
    const { data: sensors = [] } = useGetDataQuery({ resource: "sensors" });

    return (
        <DataTableControl
            columns={[
                "id",
                "title",
                "bodytext",
                "location",
                "zoom",
                "image",
                "visible",
            ]}
            tableName={"sensors"}
            data={sensors}
            EditForm={EditForm}
        />
    );
};

const EditForm = ({ datarow, cancel }) => {
    const initState = {
        title: "",
        bodytext: "",
        image: "",
        lng: 0,
        lat: 0,
        zoom: 8,
        visible: false,
    };
    const { data: images = [] } = useGetDataQuery({ resource: "images" });
    const [updateResource, { isLoading }] = useUpdateResourceMutation();
    const [formState, setFormState] = useState({
        lat: 42,
        lng: 30,
        zoom: 8,
    });

    useEffect(() => {
        if (datarow) {
            const newData = { ...datarow };
            const location = datarow?.location.split(" ");
            newData.lat = location[0];
            newData.lng = location[1];
            setFormState(newData);
        } else {
            setFormState(initState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datarow]);

    async function onSubmit(e) {
        e.preventDefault();

        await updateResource({
            resource: "sensors",
            ...formState,
            location: `${formState.lat} ${formState.lng}`,
        });

        setFormState(initState);
        cancel();
    }

    function handleChange({ target: { name, value } }) {
        setFormState({ ...formState, [name]: value });
    }

    return (
        <HStack w="100%" flexWrap="wrap">
            <VStack
                as="form"
                minW="500px"
                alignItems={"flex-start"}
                justify="center"
                p={5}
                onSubmit={onSubmit}
                w="100%"
                flex={1}
            >
                <HStack justify={"space-between"} w="100%">
                    <Heading size="sm">
                        {datarow ? `Editing #${datarow.id}` : "Creating new"}
                    </Heading>
                    <HStack>
                        <Button size="sm" type="submit">
                            {datarow ? "Save" : "Create"}
                        </Button>
                        {datarow ? (
                            <Button size="sm" onClick={cancel}>
                                Cancel
                            </Button>
                        ) : null}
                    </HStack>
                </HStack>
                <Input
                    placeholder="sensor name"
                    isDisabled={isLoading}
                    variant="filled"
                    required={true}
                    size="sm"
                    onChange={handleChange}
                    value={formState.title}
                    name="title"
                />
                <Textarea
                    maxLength={1000}
                    placeholder="body text"
                    isDisabled={isLoading}
                    minH="200px"
                    variant="filled"
                    required={true}
                    size="sm"
                    onChange={handleChange}
                    value={formState.bodytext}
                    name="bodytext"
                />
                <Select
                    isDisabled={isLoading}
                    variant="filled"
                    size="sm"
                    required={true}
                    placeholder="Select image"
                    value={formState.image}
                    onChange={handleChange}
                    name="image"
                >
                    {images
                        ?.slice()
                        ?.sort((image) => image.name)
                        ?.map((image) => (
                            <option key={image?.id} value={image.id}>
                                #{image?.id} {image.name}
                            </option>
                        ))}
                </Select>
                <HStack as={FormControl}>
                    <FormLabel>Latitude</FormLabel>
                    <Input
                        placeholder="Latitude"
                        type="number"
                        isDisabled={isLoading}
                        variant="filled"
                        required={true}
                        size="sm"
                        onChange={handleChange}
                        value={formState.lat}
                        name="lat"
                    />
                </HStack>
                <HStack as={FormControl}>
                    <FormLabel>Longitude</FormLabel>
                    <Input
                        placeholder="Longitude"
                        type="number"
                        isDisabled={isLoading}
                        variant="filled"
                        required={true}
                        size="sm"
                        onChange={handleChange}
                        value={formState.lng}
                        name="lng"
                    />
                </HStack>
                <HStack as={FormControl}>
                    <FormLabel>Zoom</FormLabel>
                    <Input
                        placeholder="Longitude"
                        type="number"
                        isDisabled={isLoading}
                        variant="filled"
                        required={true}
                        size="sm"
                        onChange={handleChange}
                        value={formState.zoom}
                        name="zoom"
                    />
                </HStack>
                <HStack
                    as={FormControl}
                    align={"center"}
                    alignContent={"center"}
                >
                    <FormLabel m={0}>Visible</FormLabel>
                    <Checkbox
                        isDisabled={isLoading}
                        size="md"
                        isChecked={formState.visible}
                        onChange={({ target: { checked } }) =>
                            setFormState({ ...formState, visible: checked })
                        }
                        name="visible"
                    />
                </HStack>
            </VStack>
            <Flex flex={1} w="100%" h="100%">
                <MyMap
                    center={{ lat: formState.lat, lng: formState.lng }}
                    zoom={formState.zoom}
                    maxZoom={formState.zoom}
                />
            </Flex>
        </HStack>
    );
};
