import { Link } from "@chakra-ui/react";
import { NavLink as ReactLink } from "react-router-dom";

export const NavLink = ({hover=null,active=null, ...props}) => {
    return (<Link as={ReactLink} 
        px={"1rem"} py={1}
        w="100%"     
        h="100%"
        textAlign={"center"}
        rounded={'md'}        
        fontWeight="bold"
        borderRadius={0}
        _hover={hover || {
            textDecoration: 'none', 
            color: "rgb(46, 78, 123)", 
            borderBottom: "2px solid rgb(46, 78, 123)",
        }}
        _activeLink={active || {
            fontWeight: "bold",
            // borderBottom: "1px solid",
            borderRadius: 0,
            transition: "0s",
            color: "rgb(46, 78, 123)",
            borderBottom: "2px solid rgb(46, 78, 123)",
        }}
        href={props.to} 
        children={props.children} 
        to={props.to}
        {...props}
    />)
};