import { useGetDataQuery, useUpdateResourceMutation } from "../../api/astrosApi";
import { Button, Divider, HStack, Heading, Input, Textarea, VStack, useBreakpointValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import HtmlToReact from "html-to-react";

const EMBBED_TEMPLATE = `<iframe style="top:0;left:0;width:100%;height:400px;border:0;" src="https://www.linkedin.com/embed/feed/update/urn:li:activity:ID_HERE" frameborder="0" title="LinkedIn"></iframe>`;

export const EmbbedForm = () => {
    const [postId, setPostId] = useState("");
    const [embbed, setEmbbed] = useState("");
    const [updateResource, {isLoading}] = useUpdateResourceMutation();
    const {data: embbedFromServer, isLoading: isLoadingData} = useGetDataQuery({resource: "embbed"});

    const [parser] = useState(new HtmlToReact.Parser());
    const Stack = useBreakpointValue({
        base: VStack,
        md: HStack,
    });
    const Orientation = useBreakpointValue({
        base: "horizontal",
        md: "vertical",
    });

    let preview = (<Heading>Not Working</Heading>);
    let error = false;
    try {
        preview = parser.parse(embbed);    
    } catch(err) {
        error = true;
    }
    

    async function onSubmit(e) {
        e.preventDefault();
        
        await updateResource({
            resource: "company",
            key: "embbed",
            value: embbed,
        });
    }


    useEffect(() => {
        setEmbbed(EMBBED_TEMPLATE.replace("ID_HERE", postId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);

    useEffect(() => {
        if(embbedFromServer) setEmbbed(embbedFromServer);
    }, [embbedFromServer]);

    return (<Stack w="100%" h="100%" divider={<Divider orientation={Orientation} />}>
        <VStack w="100%" flex={1} h="100%" p={2} overflow="auto" divider={<Divider />} as="form" onSubmit={onSubmit}>
            <Heading size="lg">
                Embbed form
            </Heading>
            <Input placeholder="post id" onChange={({target: {value}}) => setPostId(value)} value={postId} />
            
            <Textarea isDisabled={isLoading || isLoadingData} value={embbed} disabled={true} onChange={({target: {value}}) => setEmbbed(value)} minHeight="150px" />
            <Button isDisabled={isLoading || isLoadingData || error} type="submit">Save</Button>
        </VStack>
        <VStack w="100%" flex={1} h="100%" p={2} overflow="auto" divider={<Divider orientation="horizontal"/>}>
            <Heading size="lg">
                Preview
            </Heading>
            {preview}
        </VStack>
    </Stack>)
}