import { MyImg, Delimiter } from "../";
import * as colors from "../../data/colors";
import { VStack, HStack, Text } from "@chakra-ui/react";
import { useGetDataQuery } from "../../api/astrosApi";
import { baseURL } from "../../api/astrosApi";
export const Partners = (props) => {
    const { data=[] } = useGetDataQuery({resource: "partners", join: true});
    const partners = data.filter(p => p.visible).sort((p1, p2) => p1.priority - p2.priority);

    return <VStack bg={colors.bgblue1} {...props} w="100%" pt={"4em"} position="relative">
        <Delimiter mt={"1.5em"} width="1px" color={colors.neonblue} length="calc(100% - 6em)" position="absolute" ml="3em" mr="3em" zIndex={0} />
        <Text zIndex={0} pl="1.5em" pr="1.5em" bg={colors.bgblue1} fontWeight={"bold"} fontSize="2.5em" m={0} lineHeight={"1em"}>Partners</Text>
        <HStack flexWrap="wrap" spacing={4} justify="space-evenly" p={5} w="100%" userSelect={"none"} mt={6}>
            {partners.map(partner => <MyImg 
                maxWidth="120px"
                cursor="pointer"
                key={partner.name}
                height="auto"
                objectFit="sh"
                src={baseURL + "assets/" + partner.path} 
                alt={partner.name} 
                link={partner.url}
                backgroundSize="contain"
            />)}
        </HStack>
    </VStack>
}