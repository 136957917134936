import { Button, Divider, FormControl, FormErrorMessage, FormLabel, HStack, Heading, Input, VStack } from "@chakra-ui/react";
import { useGetDataQuery, useUpdateResourceMutation } from "../../api/astrosApi";
import { useEffect, useState } from "react";

export const AdminCompanyPage = () => {
    const [form, setForm] = useState({
        password: "",
        confirmPassword: "",
        worknumbers: "",
        sensornetwork: "",
        email: "",
    });
    const {data: resources, isLoading: isLoadingData} = useGetDataQuery({resource: "company"});
    const [updateResource, {isLoading: isUpdatingResource}] = useUpdateResourceMutation();


    async function updateResourceButton(key, value) {
        await updateResource({
            resource: "company",
            key: key,
            value: value,
        });

        if(key === "password") {
            let newForm = {...form};
            newForm.password = "";
            newForm.confirmPassword = "";
            setForm(newForm);
        }
    }

    useEffect(() => {
        if(resources) {
            let newForm = {...form};
            resources.forEach(row => {
                newForm[row.id] = row.value;
            });
            setForm(newForm);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resources]);

    const handleChange = ({target: {value, name}}) => {
        setForm({...form, [name]: value});
    }
    const isLoading = isLoadingData || isUpdatingResource;

    return (<VStack h="100%" w="100%" alignItems={"flex-start"} divider={<Divider />}>
        <Heading>Company settings</Heading>
        <VStack alignItems={"flex-start"} as="form" p={2} onSubmit={(e) => {
            e.preventDefault();
            if(form.password !== form.confirmPassword) return;
            updateResourceButton("password", form.password);
        }}>
            <HStack as={FormControl}>
                <FormLabel m={0}>New password</FormLabel>
                <Input isDisabled={isLoading} minLength={4} value={form.password} required={true} name="password" onChange={handleChange} type="password" />
            </HStack>       
            <HStack as={FormControl} isInvalid={form.password !== form.confirmPassword}>
                <FormLabel m={0}>Confirm Password</FormLabel>
                <Input isDisabled={isLoading} minLength={4} value={form.confirmPassword} name="confirmPassword" onChange={handleChange} required={true} type="password" />
                <FormErrorMessage>Passwords don't match</FormErrorMessage>
            </HStack>
            <Button type="submit" isDisabled={isLoading}>Change password</Button>
        </VStack>

        <FormControl as={"form"} p={1}  display="flex" flexDir={"row"} alignItems={"center"} columnGap={"1em"}
            onSubmit={(e) => {
                e.preventDefault();
                updateResourceButton("worknumbers", form.worknumbers);
            }
        }>
            <FormLabel m={0}>Work numbers</FormLabel>
            <Input 
                isDisabled={isLoading}
                required={true}
                maxW="150px"
                placeholder="12 12 12" 
                value={form.worknumbers} 
                onChange={handleChange} 
                name="worknumbers"
            />
            <Button type="submit" isDisabled={isLoading}>Save</Button>
        </FormControl>

        <FormControl as={"form"} p={1}  display="flex" flexDir={"row"} alignItems={"center"} columnGap={"1em"}
            onSubmit={(e) => {
                e.preventDefault();
                updateResourceButton("sensornetwork", form.sensornetwork);
            }
        }>
            <FormLabel m={0}>Sensor network</FormLabel>
            <Input 
                required={true}
                maxW="150px"
                isDisabled={isLoading}
                placeholder="12 12 12" 
                value={form.sensornetwork} 
                onChange={handleChange} 
                name="sensornetwork"
            />
            <Button type="submit" isDisabled={isLoading}>Save</Button>
        </FormControl>


        <FormControl as={"form"} p={1}  display="flex" flexDir={"row"} alignItems={"center"} columnGap={"1em"}
            onSubmit={(e) => {
                e.preventDefault();
                updateResourceButton("email", form.email);
            }
        }>
            <FormLabel m={0}>Email</FormLabel>
            <Input 
                required={true}
                type="email"
                isDisabled={isLoading}
                maxW="250px"
                placeholder="office@astros.sk" 
                value={form.email} 
                onChange={handleChange} 
                name="email"
            />
            <Button type="submit" isDisabled={isLoading}>Save</Button>
        </FormControl>
    </VStack>)
}