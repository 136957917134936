export const address = {
    title: "Headquarter",
    lines: [
        "Astros Solutions s.r.o.",
        "Faculty of Mathematics, Physics and Informatics",
        "Comenius University Bratislava",
        "Mlynska Dolina F1",
        "842 48 Bratislava, Slovakia",
    ],
};
