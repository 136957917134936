import { useEffect } from "react";
import titleLogo from "../../assets/titleLogo.png";
import earthBG from "../../assets/earth_2024.jpg";

import { WorkNumberParent, BannerForm, Partners, SensorsMP, ProjectsMP, MyImg, Socials } from "../../components";
import * as colors from "../../data/colors";
import { Box, Heading, Text, VStack, useColorMode } from "@chakra-ui/react";

export const MainPage = (props) => {
    const { colorMode, toggleColorMode } = useColorMode()
    useEffect(() => {
        if(colorMode ==="light") {
            toggleColorMode()
        }
    }, [colorMode, toggleColorMode])

    return (<VStack width="100%" flexGrow={1} alignItems={{base: "center", lg:"flex-end"}} 
        overflow="auto" backgroundRepeat="no-repeat" backgroundPosition={"-200px"}
        backgroundImage={earthBG} bgColor={"white"} backgroundSize={"contain"}
        {...props} position="relative" spacing={0}>
        <VStack w={{base: "100%", xl: "50%", lg:"70%", md:"80%"}} 
            alignItems="center" color={colors.textBlue} spacing={5} 
            pr={{base: "1%", lg: "2em"}} bg="white" borderRadius="1em"
        >
            <MyImg src={titleLogo} width="300px" pt={6} pb={10} pr={"6%"} />
            <Box as="article" pb={5} display="flex" flexDirection="column">
                <Heading mb={4} textAlign="center">Dedicated to Space Safety and Long-Term Sustainability</Heading>
                <Text fontSize="2xl" textAlign="justify" w="100%" p={2} >
                    The mission of Astros Solutions is provision of expertise in Space Situation Awareness (SSA) and Space Traffic Management (STM), ground-based data acquisition of satellites, space debris and near-earth asteroids using own hardware and software solutions, development of new technologies, methods and products related to Space Safety and focus is on supporting the long-term sustainability of space environment.
                </Text>
            </Box>
            <SensorsMP />
            <ProjectsMP />
            <Socials />
        </VStack>
        <VStack width="100%" justify="center" color="white" mt={20} spacing={0}>  
            <WorkNumberParent />
            <Partners />
        </VStack>
        <BannerForm />
    </VStack>)
}