import { useGetDataQuery } from "../../api/astrosApi";
import { BriefSlider } from "../../components";
import * as colors from "../../data/colors";
import { VStack } from "@chakra-ui/react";
import earthBG from "../../assets/earth_2024.jpg";

export const WorkPage = (props) => {
    const {data=[]} = useGetDataQuery({resource: "projects"});
    const projects = data.filter(p => p.visible).sort((p1, p2) => p1.priority-p2.priority);
    return (<VStack
            width="100%" {...props} 
            flexGrow={1} overflow="auto" rowGap={10} color={colors.textBlue}
            backgroundRepeat="no-repeat" backgroundPosition={"-400px"} backgroundImage={earthBG} bgColor={"white"} backgroundSize={"contain"}
            >
            <BriefSlider arrayItem={projects} title="Projects"/>
    </VStack>)
}