import { Button, FormControl, FormLabel, HStack, Heading, Img, Input, Text, Textarea, VStack, useBreakpointValue } from "@chakra-ui/react";
import AstrosUnderline from "../../assets/title_underscore.svg";
import * as colors from "../../data/colors";
import { SocialsIcons } from "../socials";
import titleLogo from "../../assets/titleLogo2.png";
import { useSendEmailMutation } from "../../api/astrosApi";
import { useState } from "react";

const formInit = {
    name: "",
    email: "",
    content: "",
}

export const BannerForm = ({showText=true, logoAsHeading=false}) => {
    const [formState, setFormState] = useState(formInit);
    const handleChange = ({target: { name, value }}) => setFormState((prev) => ({ ...prev, [name]: value }))

    const [sendEmail, { isLoading, isSuccess, isError }] = useSendEmailMutation();    

    const Stack = useBreakpointValue({
        base: VStack,
        sm: VStack,
        md: HStack,
    });
    const showSocialIcons = useBreakpointValue({
        base: true,
        md: false,
    });
    function onSubmit(e) {
        if(isLoading) return;
        e.preventDefault();
        sendEmail({
            resource: "sendEmail",
            data: {...formState},
        }).then(({error}) => {
            if(!error) {
                setFormState(formInit);
            }
        })
    }
    return (<VStack as="form" width="100%" p={4} bg={colors.bgblue2} color="white" height="fit-content" spacing={10} justify="center" onSubmit={onSubmit}>
        <VStack justify="center" maxW="80%">
            {logoAsHeading ? (<Img src={titleLogo} width="250px" />) : (<Heading textAlign="center" mt={0}>Astros solutions</Heading>)}
            <Img src={AstrosUnderline} maxW={logoAsHeading ? "" : "200px"} />
            { !showText || <Text textAlign="center">
                We want to be your gateway to excellent solutions. Contact us, and together, we can achieve your goals.
            </Text>}
        </VStack>
        {!showSocialIcons || <SocialsIcons />}
        <Heading color={colors.neonblue}>Contact us!</Heading>

        <Stack h="100%" alignItems="flex-start" justify="center" spacing={"15%"} w={{base: "100%", sm: "75%", md: "50%"}}>
            <FormControl as={VStack} alignItems={"flex-start"}>
                <FormLabel htmlFor="name" m={0}>Your name:</FormLabel>
                <Input id="name" name="name" required={true}  outlineColor={colors.neonblue} value={formState.name} onChange={handleChange} />
            </FormControl>
            <FormControl as={VStack} alignItems="flex-start">
                <FormLabel htmlFor="email" m={0}>Your e-mail:</FormLabel>
                <Input id="email" name="email" required={true} outlineColor={colors.neonblue} value={formState.email} type="email" onChange={handleChange} />
            </FormControl>
        </Stack>
        <FormControl width="fit-content" as={VStack} w={{base: "100%", sm: "75%", md: "50%"}} alignItems="flex-start">
            <FormLabel htmlFor="idea">Your Idea: </FormLabel>
            <Textarea id="idea" w="100%" name="content" required={true}  outlineColor={colors.neonblue} value={formState.content} onChange={handleChange} />
        </FormControl>
        {!isError || <p>Try to contact us later. Our Mail service is not responding.</p>}
        {!isSuccess || <p>Email has been successfully sent! Thanks for contacting us.</p>}
        <Button type="submit" p={2} pl={"2em"} pr="2em" bg={colors.neonblue} color={colors.bgblue2} isLoading={isLoading}>Send</Button>

    </VStack>)
}
