import { links } from "../../data";
import { MyImg, SocialsIcons, NavLink } from "../";
import NavLogo from "../../assets/navLogo.png";
import { HStack, useBreakpointValue } from "@chakra-ui/react";

export const MyNavbar = () => {
    const includeIcons = useBreakpointValue({
        base: false,
        sm: false,
        md: true,
    });
    const includeLogo = useBreakpointValue({
        base: false,
        sm: true,
    });
    return (
        <HStack
            width="100%"
            pl={2}
            pr={2}
            backgroundColor="white"
            columnGap={5}
            color="rgb(46, 78, 123)"
            borderBottom="1px solid"
            justify="space-between"
            minH={"3.5em"}
            overflowX={"auto"}
            overflowY={"hidden"}
        >
            <HStack justifyContent={"space-between"}>
                {!includeLogo || (
                    <MyImg src={NavLogo} w={14} h={14} p={2} ml={2} />
                )}
                <HStack columnGap={2} fontSize={{ base: "0.9em", sm: "1em" }}>
                    {links.map((link) => (
                        <NavLink key={link.name} to={link.to.toLowerCase()}>
                            <nobr>{link.name}</nobr>
                        </NavLink>
                    ))}
                </HStack>
            </HStack>
            {!includeIcons || <SocialsIcons />}
        </HStack>
    );
};
