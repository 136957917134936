import {
    Box,
    Flex,
    HStack,
    Heading,
    Img,
    Spinner,
    VStack,
    useBreakpointValue,
} from "@chakra-ui/react";
import { DotIndicator, MyImg } from "../../components";
import { useState, useEffect } from "react";
import LeftArrow from "../../assets/arrow_gray_left.svg";
import RightArrow from "../../assets/arrow_gray_right.svg";
import LeftColArrow from "../../assets/arrow_colored_left.svg";
import RightColArrow from "../../assets/arrow_colored_right.svg";
import { useLocation } from "react-router-dom";
import { baseURL } from "../../api/astrosApi";
import HtmlToReact from "html-to-react";

export const BriefSlider = ({ arrayItem, title, effects = null, ...props }) => {
    const location = useLocation();

    const [page, setPage] = useState(location.state || 0);
    const item = arrayItem[page];

    const [parser] = useState(new HtmlToReact.Parser());
    let bodytext;
    try {
        bodytext = parser.parse(item.bodytext);
    } catch (err) {
        bodytext = item?.bodytext;
    }
    useEffect(() => {
        if (!item) return;
        if (effects) {
            effects.forEach((effect) =>
                effect["function"](effect.format(item[effect.property]))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    function nextPage() {
        setPage(Math.min(page + 1, arrayItem.length - 1));
    }
    function previousPage() {
        setPage(Math.max(0, page - 1));
    }

    const showArrows = useBreakpointValue({
        base: false,
        md: true,
    });

    // eslint-disable-next-line eqeqeq
    if (!item) {
        return <Spinner />;
    }
    let itemtitle, bigtitle;
    if (title == null) {
        bigtitle = item.title;
    } else {
        bigtitle = title;
        itemtitle = item.title;
    }
    return (
        <HStack h="100%" w="100%" alignItems="flex-start" position="relative">
            {!showArrows || (
                <Img
                    src={page <= 0 ? LeftArrow : LeftColArrow}
                    onClick={previousPage}
                    zIndex={99}
                    minW="5em"
                    minH="5em"
                    maxW="5em"
                    maxH="5em"
                    userSelect="none"
                    position="fixed"
                    left={{ md: 0, lg: "15%" }}
                    top="calc(50% - 5em)"
                />
            )}
            <VStack
                w="100%"
                p={0}
                bgColor="rgba(255, 255, 255, 0.8)"
                flex={1}
                h="100%"
                overflow={"auto"}
                alignItems={"center"}
            >
                <Heading userSelect="none" pl="1em" pr="1em" mb={2} mt={4}>
                    {bigtitle}
                </Heading>
                <DotIndicator
                    numberOfDots={arrayItem.length}
                    currentDotIndex={page}
                    onClickChange={setPage}
                    p={2}
                    pb={5}
                    w="100%"
                    mb={10}
                    justify="center"
                    position="sticky"
                    top={0}
                />
                <VStack
                    alignItems={["center", "flex-start"]}
                    w={{
                        base: "95%",
                        sm: "80%",
                        md: "50%",
                        // lg: "50%",
                    }}
                >
                    {itemtitle != null ? (
                        <Heading
                            textAlign="center"
                            m={0}
                            mb={6}
                            p={0}
                            size="md"
                        >
                            {itemtitle}
                        </Heading>
                    ) : null}
                    <HStack
                        rowGap={10}
                        w="100%"
                        height="100%"
                        borderRadius="10px"
                        flexDirection={["column", "column", "row"]}
                        h="min-content"
                        alignItems={["flex-start", "center"]}
                        maxH="min-content"
                        spacing={0}
                        justify="space-between"
                    >
                        <Box
                            textAlign={"justify"}
                            alignItems={"flex-start"}
                            maxW={{ base: "100%", lg: "49%" }}
                            w={["100%", "auto"]}
                            p={2}
                        >
                            {bodytext}
                        </Box>
                        <Flex
                            maxW={{ base: "100%", lg: "49%" }}
                            w={["100%", "auto"]}
                            justify="center"
                            alignItems="flex-start"
                            flex={1}
                        >
                            <MyImg
                                src={baseURL + "assets/" + item?.path}
                                onfs={true}
                                flexBasis="content"
                                objectFit="contain"
                                p={2}
                                minW="200px"
                                maxH="300px"
                                borderRadius="1em"
                            />
                        </Flex>
                    </HStack>
                    {props.children}
                </VStack>
            </VStack>
            {!showArrows || (
                <Img
                    src={
                        page >= arrayItem.length - 1
                            ? RightArrow
                            : RightColArrow
                    }
                    onClick={nextPage}
                    zIndex={99}
                    minW="5em"
                    minH="5em"
                    maxW="5em"
                    maxH="5em"
                    position="fixed"
                    right={{ md: 0, lg: "15%" }}
                    top="calc(50% - 5em)"
                    userSelect="none"
                />
            )}
        </HStack>
    );
};
