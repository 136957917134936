import {
    Button,
    HStack,
    Heading,
    IconButton,
    Img,
    Input,
    Select,
    Text,
    VStack,
} from "@chakra-ui/react";
import {
    useGetDataQuery,
    baseURL,
    useUpdateResourceMutation,
} from "../../api/astrosApi";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";

const MAX_IMAGE_SIZE_MB = 3;

export const AdminImagesPage = () => {
    const { data: images = [], isLoading } = useGetDataQuery({
        resource: "images",
    });
    const [editing, setEditing] = useState(null);
    const [editValue, setEditValue] = useState("");
    const [file, setFile] = useState(null);
    const [name, setName] = useState("");
    const [replace, setReplace] = useState(-1);

    const [updateResource, { isLoading: isUpdatingResource }] =
        useUpdateResourceMutation();

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    function startEdit(image) {
        setEditing(image.id);
        setEditValue(image.name);
    }

    async function createImage(e) {
        e.preventDefault();
        const fd = new FormData();
        if (file?.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
            return alert(`Max ${MAX_IMAGE_SIZE_MB} MB!`);
        }

        fd.append("file", file);
        fd.append("name", name);
        fd.append("resource", "file");
        if (replace) {
            fd.append("id", replace);
        }

        await updateResource(fd);
        setName("");
    }

    async function endEditing(e, cancel = false) {
        e.preventDefault();
        setEditing(null);
        if (cancel) return;

        await updateResource({
            resource: "image",
            id: editing,
            name: editValue,
        });
    }

    async function deleteImage(id) {
        await updateResource({
            id: id,
            action: "delete",
            resource: "image",
        });
    }

    return (
        <VStack w="100%" h="100%" alignItems={"flex-start"} overflowY={"auto"}>
            <Heading>Images</Heading>
            <HStack as="form" w="fit-content" onSubmit={createImage}>
                <Select
                    size="sm"
                    width="23em"
                    value={replace}
                    disabled={editing || isUpdatingResource}
                    onChange={(e) => setReplace(e.target.value)}
                >
                    <option value={-1}>New image</option>
                    {images?.map((image) => (
                        <option key={image.id} value={image.id}>
                            Replace #{image.id}
                        </option>
                    ))}
                </Select>
                <Input
                    disabled={isUpdatingResource || editing}
                    required={true}
                    placeholder="Image name"
                    size="sm"
                    variant="filled"
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                />
                <Input
                    disabled={isUpdatingResource || editing}
                    required={true}
                    onChange={handleFileChange}
                    id="file"
                    type="file"
                    size="sm"
                    variant="filled"
                />
                <Button
                    type="submit"
                    size="sm"
                    pl="2em"
                    pr="2em"
                    isDisabled={editing || isUpdatingResource}
                >
                    Upload
                </Button>
            </HStack>
            {!isLoading || <Heading>LOADING</Heading>}
            {!images || (
                <HStack as="form" onSubmit={endEditing} flexWrap="wrap">
                    {images.map((image) => (
                        <VStack
                            p={2}
                            border="2px solid gray"
                            borderRadius="10px"
                            key={image.id}
                        >
                            {editing === image.id ? (
                                <HStack spacing={1}>
                                    <Input
                                        maxWidth="140px"
                                        value={editValue}
                                        height="1.5em"
                                        onChange={({ target: { value } }) =>
                                            setEditValue(value)
                                        }
                                    />
                                    <IconButton
                                        size="xs"
                                        icon={<FaCheck />}
                                        onClick={endEditing}
                                    />
                                    <Button
                                        size="xs"
                                        onClick={(e) => endEditing(e, true)}
                                    >
                                        X
                                    </Button>
                                </HStack>
                            ) : (
                                <HStack w="100%">
                                    <Text
                                        cursor="pointer"
                                        w="100%"
                                        onClick={() => startEdit(image)}
                                    >
                                        #{image.id} {image.name}
                                    </Text>
                                    <Button
                                        colorScheme="red"
                                        size="xxs"
                                        pl={1}
                                        pr={1}
                                        onClick={() => deleteImage(image.id)}
                                        isDisabled={isLoading || editing}
                                    >
                                        X
                                    </Button>
                                </HStack>
                            )}
                            <Img
                                key={image.id}
                                src={`${baseURL}/assets/${image.path}`}
                                alt={image.name}
                                w="200px"
                                h="150px"
                                objectFit="contain"
                            />
                        </VStack>
                    ))}
                </HStack>
            )}
        </VStack>
    );
};
