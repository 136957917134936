/* eslint-disable eqeqeq */
import { Button, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useUpdateResourceMutation } from "../../api/astrosApi";
import { useState } from "react";

export const DataTableControl = ({data, tableName, columns, EditForm}) => {
    const [editing, setEditing] = useState(null);
    const [ordering, setOrdering] = useState(false);
    const [order, setOrder] = useState([]);
    const [updateResource, {isLoading: isUpdatingResource}] = useUpdateResourceMutation();


    async function toggleOrdering() {
        if(!ordering) {
            setOrder(data.slice().sort((p1, p2) => p1.priority - p2.priority));
        } else {
            let newOrder = {}
            order.forEach((p, i) => newOrder[p.id] = i);
            await updateResource({
                action: "order",
                resource: tableName,
                order: newOrder,
            })
        }
        setEditing(null);
        setOrdering(!ordering);
    }

    function moveUp(item) {
        let neworder = order.slice();
        let index = neworder.indexOf(item);
        if(index+1 >= data.length) return;
        let tmp = neworder[index+1];
        neworder[index+1] = item;
        neworder[index] = tmp;
        setOrder(neworder);
    }
    function moveDown(item) {
        let neworder = order.slice();
        let index = neworder.indexOf(item);
        if(index-1 < 0) return;
        let tmp = neworder[index-1];
        neworder[index-1] = item;
        neworder[index] = tmp;
        setOrder(neworder);
    }

    function deleteButton(itemId) {
        updateResource({
            resource: tableName,
            action: "delete",
            id: itemId,
        })
    }
    const sortedData = !data ? [] : (ordering ? order : data.slice().sort((p1, p2) => p1.priority - p2.priority));
    return (<VStack w="100%" h="100%" alignItems={"flex-start"} overflow={"auto"} flex={1}>
        <Heading p={1}>{tableName}</Heading>
        <Button isDisabled={!data?.length || data.length <= 1} mb={1} size="sm" onClick={toggleOrdering}>{ordering ? "Confirm order" : "Edit order"}</Button>
        <TableContainer w="100%" maxH="300px" overflowY={"auto"}>
            <Table onSubmit={(e) => e.preventDefault()} style={{tableLayout: "fixed"}}>
                <Thead>
                    <Tr>
                        {columns.map(col => (<Th style={{maxWidth: "100px"}} id={col}>{ col }</Th>))}
                        {ordering ? (<Th>Order</Th>) : <Th>Actions</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedData.map((datarow, index) =>
                        (<Tr key={datarow.id}>
                            {columns.map((col) => (!["image", "visible"].includes(col) ? (<Th overflow={"hidden"} id={col}>{datarow[col.toLowerCase()]}</Th>) : null))}
                            <Th>#{datarow.imageid} {datarow.imagename}</Th>
                            <Th>{datarow.visible == 1 ? "SHOW" : "HIDDEN"}</Th>
                            {ordering ? (<Th>
                                <IconButton size="xs" isDisabled={index<=0} icon={<MdKeyboardArrowUp />} mr={"0.25em"} onClick={() => moveDown(datarow)}/>
                                <IconButton size="xs" isDisabled={index >= data.length-1}  icon={<MdKeyboardArrowDown />} onClick={() => moveUp(datarow)}/>
                            </Th>) : 
                            (<Th>
                                <Button isDisabled={isUpdatingResource} size="xs" mr="0.5em" onClick={() => setEditing(datarow)}>Edit</Button>
                                <Button isDisabled={isUpdatingResource} size="xs" onClick={() => deleteButton(datarow.id)}>Delete</Button>
                            </Th>)
                            }
                        </Tr>)
                    )}
                </Tbody>
            </Table>
        </TableContainer>
        {!ordering ?
            (<EditForm datarow={editing} cancel={() => setEditing(null)}/>) : null
        }
    </VStack>)
}