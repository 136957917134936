import { MapContainer, TileLayer, useMap  } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { PulsingIcon } from "./pulsingicon";
import { useEffect } from "react";

export const MyMap = ({ center, tileVariant="dark", ...props }) => {
    // const accessToken = "PyTJUlEU1OPJwCJlW1k0NC8JIt2CALpyuj7uc066O7XbdZCjWEL3WYJIk6dnXtps";
    const tiles = {
        dark: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
        light: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
    }
    
    return (<MapContainer
        attributionControl={false}
        center={center}
        zoom={7}
        maxZoom={7}
        {...props}
        style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            margin: "2em",
            padding: "2em",
            zIndex: 10,
        }}>
            <RecenterAutomatically lat={center.lat} lng={center.lng} />
            <RezoomAutomatically zoom={props.zoom} maxZoom={props.maxZoom} />
            <PulsingIcon center={center} />
            <TileLayer url={tiles[tileVariant]} />
        </MapContainer>)
}

const RecenterAutomatically = ({lat, lng}) => {
    const map = useMap();
    useEffect(() => {
        try {
            map.setView([lat, lng]);
        } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return null;
}

const RezoomAutomatically = ({zoom, maxZoom}) => {
    const map = useMap();
    useEffect(() => {
        try {
            map.setMaxZoom(maxZoom);
            map.setZoom(zoom);
        } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoom, maxZoom]);
    return null
}