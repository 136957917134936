import {
    Box,
    Button,
    Heading,
    HStack,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useGetDataQuery } from "../../api/astrosApi";

export const EmailsPage = () => {
    var { data: emails = [], isLoading } = useGetDataQuery({
        resource: "emails",
    });
    const [showEmail, setShowEmail] = useState(null);
    if (emails == null) {
        emails = [];
    }
    return (
        <VStack h="100%" w="100%" justify={"flex-start"}>
            <Heading>Emails</Heading>
            {isLoading ? <Spinner /> : null}
            <TableContainer w="100%">
                <Table w="100%">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Message</Th>
                            <Th>Date</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {emails.map((email) => (
                            <DisplayEmailRow
                                email={email}
                                setShowEmail={setShowEmail}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <DisplayEmailFs email={showEmail} setShowEmail={setShowEmail} />
        </VStack>
    );
};

const DisplayEmailRow = ({ email, setShowEmail }) => {
    if (!email) {
        return null;
    }
    return (
        <Tr>
            <Td>{email.id}</Td>
            <Td>{email.name}</Td>
            <Td>{email.from_email}</Td>
            <Td overflow="hidden" maxW="3em">
                {email.message}
            </Td>
            <Td>{email.date}</Td>
            <Td>
                <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => setShowEmail(email)}
                >
                    Show
                </Button>
            </Td>
        </Tr>
    );
};

const DisplayEmailFs = ({ email, setShowEmail }) => {
    if (email == null) {
        return null;
    }
    return (
        <Box
            zIndex={10}
            position={"absolute"}
            left={0}
            top={0}
            w="100vw"
            h="100vh"
            bg="rgba(0,0,0,0.2)"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={() => setShowEmail(null)}
        >
            {
                <VStack
                    p={6}
                    bg="rgb(55,55,55)"
                    borderRadius={"0.5em"}
                    onClick={(e) => e.stopPropagation()}
                    position="relative"
                >
                    <Button
                        position="absolute"
                        top={2}
                        right={2}
                        size="xs"
                        colorScheme="yellow"
                        onClick={() => setShowEmail(null)}
                    >
                        Close
                    </Button>
                    <VStack
                        w="100%"
                        alignItems="flex-start"
                        textDecoration={"none"}
                    >
                        <Heading size="md" fontWeight={"light"}>
                            From: {email.name}
                        </Heading>
                        <Heading size="md" fontWeight={"light"}>
                            Contact: {email.from_email}
                        </Heading>
                    </VStack>
                    <Textarea
                        w="400px"
                        h="300px"
                        resize="none"
                        readOnly={true}
                        defaultValue={email.message}
                        border="none"
                    />
                    <HStack justify={"flex-end"} w="100%">
                        <Heading size="xs">{email.date}</Heading>
                    </HStack>
                </VStack>
            }
        </Box>
    );
};
