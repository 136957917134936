import {
    BrowserRouter,
    Route,
    Routes,
    HashRouter,
    Navigate,
} from "react-router-dom";
import { links } from "../data";
import {
    AdminPage,
    ContactPage,
    MainPage,
    SensorsPage,
    // ServicesPage,
    WorkPage,
} from "../pages";
import { AnimatePresence, motion } from "framer-motion";
import { RosiePage } from "../pages/rosiePage/rosiePage";

const pathElements = {
    "/work": WorkPage,
    "/contact": ContactPage,
    "/sensors": SensorsPage,
    "/rosie": RosiePage,
    // "/services": ServicesPage,
};

export const MyRouter = ({ children, ...props }) => {
    return (
        <HashRouter {...props}>
            {children}
            <AnimatePresence>
                <Routes>
                    <Route
                        index
                        element={
                            <MainPage
                                as={motion.main}
                                initial={{ opacity: 0, y: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            />
                        }
                    />
                    {links.map((link) => {
                        const El = pathElements[link.to];
                        return (
                            <Route
                                key={link.to}
                                path={link.to}
                                element={
                                    <El
                                        as={motion.main}
                                        initial={{ opacity: 0, y: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    />
                                }
                            />
                        );
                    })}
                    <Route
                        path="/admin/*"
                        element={
                            <AdminPage
                                as={motion.main}
                                initial={{ opacity: 0, y: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </AnimatePresence>
        </HashRouter>
    );
};

MyRouter.propTypes = BrowserRouter.propTypes;
