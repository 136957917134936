import { Flex, Heading, VStack } from "@chakra-ui/react";
import { NavLink } from "../navlink";

const adminlinks = [
    {
        to: "./images",
        name: "Images",
    },
    {
        to: "./partners",
        name: "Partners",
    },
    {
        to: "./embbed",
        name: "News feed",
    },
    {
        to: "./projects",
        name: "Projects",
    },
    {
        to: "./services",
        name: "Services",
    },
    {
        to: "./sensors",
        name: "Sensors",
    },
    {
        to: "./settings",
        name: "Company settings",
    },
    {
        to: "./emails",
        name: "Emails",
    },
];

export const AdminNavbar = () => {
    return (
        <VStack
            color="black"
            w="175px"
            bg="white"
            borderRight="1px solid black"
            h="100%"
        >
            <Heading
                color="black"
                size="md"
                w="100%"
                textAlign="center"
                p={2}
                borderBottom={"1px solid black"}
            >
                Admin zone
            </Heading>
            <VStack spacing={"0.25em"} w="100%">
                {adminlinks.map((link) => (
                    <Flex
                        key={link.name}
                        to={link.to}
                        as={NavLink}
                        w="100%"
                        p={2}
                        minH="2em"
                        fontSize={"1.2em"}
                        align={"center"}
                        justify={"center"}
                        hover={{
                            textDecoration: "none",
                            color: "white",
                            background: "black",
                        }}
                        active={{
                            color: "white",
                            background: "blue",
                        }}
                    >
                        {link.name}
                    </Flex>
                ))}
            </VStack>
        </VStack>
    );
};
