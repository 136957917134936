/* eslint-disable eqeqeq */
import { Button, Checkbox, Heading, IconButton, Input, Select, VStack } from "@chakra-ui/react";
import { useGetDataQuery, useUpdateResourceMutation } from "../../api/astrosApi";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer,
  } from '@chakra-ui/react'
import { useState } from "react";

            
export const AdminPartnersPage = () => {
    const {data: images=[]} = useGetDataQuery({resource: "images"});
    const {data: partners=[]} = useGetDataQuery({resource: "partners"});
    const [editing, setEditing] = useState(null);
    const [ordering, setOrdering] = useState(false);
    const [order, setOrder] = useState([]);
    const [updateResource, {isLoading: isUpdatingResource}] = useUpdateResourceMutation();

    async function toggleOrdering() {
        if(!ordering) {
            setOrder(partners.slice().sort((p1, p2) => p1.priority - p2.priority));
        } else {
            let newOrder = {}
            order.forEach((p, i) => newOrder[p.id] = i);
            await updateResource({
                action: "order",
                resource: "partners",
                order: newOrder,
            })
        }
        setEditing(null);
        setOrdering(!ordering);
    }

    function moveUp(partner) {
        let neworder = order.slice();
        let index = neworder.indexOf(partner);
        if(index+1 >= partners.length) return;
        let tmp = neworder[index+1];
        neworder[index+1] = partner;
        neworder[index] = tmp;
        setOrder(neworder);
    }
    function moveDown(partner) {
        let neworder = order.slice();
        let index = neworder.indexOf(partner);
        if(index-1 < 0) return;
        let tmp = neworder[index-1];
        neworder[index-1] = partner;
        neworder[index] = tmp;
        setOrder(neworder);
    }

    function deleteButton(partnerId) {
        updateResource({
            resource: "partners",
            action: "delete",
            id: partnerId,
        })
    }
    const sortedPartners = !partners ? [] : (ordering ? order : partners.slice().sort((p1, p2) => p1.priority - p2.priority));
    return (<VStack w="100%" h="100%" alignItems={"flex-start"} overflow={"auto"} flex={1}>
        <Heading>Partners</Heading>
        <Button isDisabled={!partners?.length || partners.length <= 1} mb={1} size="sm" onClick={toggleOrdering}>{ordering ? "Confirm order" : "Edit order"}</Button>
        <TableContainer w="100%">
            <Table onSubmit={(e) => e.preventDefault()}>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Url</Th>
                        <Th>Assigned Image</Th>
                        <Th>Visible</Th>
                        {ordering ? (<Th>Order</Th>) : <Th>Actions</Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedPartners.map((partner, index) => editing === partner.id ? (<PartnerForm key={partner.id} partner={partner} images={images} cancel={() => setEditing(null)}/>) : 
                        (<Tr key={partner.id}>
                            <Th>{partner.id}</Th>
                            <Th>{partner.name}</Th>
                            <Th>{partner.url}</Th>
                            <Th>#{images?.find(img => img.id === partner.image)?.id} {images?.find(img => img.id === partner.image)?.name}</Th>
                            <Th>{partner.visible == 1 ? "SHOW" : "HIDDEN"}</Th>
                            {ordering ? (<Th>
                                <IconButton size="xs" isDisabled={index<=0} icon={<MdKeyboardArrowUp />} mr={"0.25em"} onClick={() => moveDown(partner)}/>
                                <IconButton size="xs" isDisabled={index >= partners.length-1}  icon={<MdKeyboardArrowDown />} onClick={() => moveUp(partner)}/>
                            </Th>) : 
                            (<Th>
                                <Button isDisabled={isUpdatingResource} size="xs" mr="0.5em" onClick={() => setEditing(partner.id)}>Edit</Button>
                                <Button isDisabled={isUpdatingResource} size="xs" onClick={() => deleteButton(partner.id)}>Delete</Button>
                            </Th>)
                            }
                        </Tr>)
                    )}
                    {!editing && !ordering ?
                        (<PartnerForm images={images} cancel={() => setEditing(null)}/>) : null
                    }
                </Tbody>
            </Table>
        </TableContainer>
    </VStack>)
}

const PartnerForm = ({partner, images, cancel}) => {
    const initState = {
        image: "",
        url: "",
        name: "",
        visible: 0,
    }
    const [form, setForm] = useState(partner || initState);
    const [post, { isLoading }] = useUpdateResourceMutation();    
    const handleChange = ({target: {name, value}}) => {
        setForm({...form, [name]: value});
    }
    async function submit(){
        await post({resource: "partners", ...form});
        cancel();
        if(!partner) setForm(initState);
    }
    return (
        <Tr>
            <Th>-</Th>
            <Th><Input isDisabled={isLoading} variant="filled" required={true} maxW="300px" size="sm" onChange={handleChange} value={form.name} name="name"/></Th>
            <Th><Input isDisabled={isLoading} variant="filled" required={true} size="sm" onChange={handleChange} value={form.url} name="url" /></Th>
            <Th><Select isDisabled={isLoading} variant="filled" size="sm" required={true} placeholder='Select image' value={form.image} onChange={handleChange} name="image">
                {images?.slice()?.sort(image=>image.name)?.map(image => (
                    <option key={image?.id} value={image.id}>#{image?.id} {image.name}</option>
                ))}
            </Select></Th>
            <Th>
                <Checkbox isDisabled={isLoading} size="md" isChecked={form.visible} onChange={({target: { checked }}) => setForm({...form, visible: checked})} name="visible" />
            </Th>
            {isLoading ? (<Th>SPINNER</Th>) :
            (<Th>
                <Button size="xs" mr="0.5em" onClick={submit}>{partner ? "Save" : "Create"}</Button>
                {partner ? <Button size="xs" onClick={cancel}>Cancel</Button> : null}
            </Th>)}
        </Tr>)

}