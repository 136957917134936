import { HStack, Heading, VStack } from "@chakra-ui/react";
import { address } from "../../data/information";


export const Addressbox = (props) => {    
    return (
        <VStack {...props} p={10} spacing={10} overflow={"auto"} justifyContent={"center"}>
            <HStack>
                <Heading size={{base: "lg", sm: "2xl", md: "lg", lg: "2xl"}} pl={5} pr={5}><nobr>Meet us</nobr></Heading>
            </HStack>            
            
            <VStack alignItems="flex-start" pl={"1em"}>
                <Heading size={["sm", "lg", "md", "lg"]}>{address.title}</Heading>
                { address.lines.map(line => (<address key={line}>{line}</address>))}
            </VStack>

        </VStack>
    )
}

