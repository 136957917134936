import { Flex, HStack, Heading, Img, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import { MyImg } from "../../components"
import TitleUnderScore from "../../assets/title_underscore.svg";

import LeftArrow from "../../assets/arrow_gray_left.svg";
import RightArrow from "../../assets/arrow_gray_right.svg";
import LeftColArrow from "../../assets/arrow_colored_left.svg";
import RightColArrow from "../../assets/arrow_colored_right.svg";
import * as colors from "../../data/colors";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { baseURL, useGetDataQuery } from "../../api/astrosApi";


export const SensorsMP = () => {
    const {data =[]} = useGetDataQuery({resource: "sensors"});
    const sensors = data.filter(s => s.visible).sort((s1, s2) => s1.priority - s2.priority);
    
    const navigate = useNavigate();
    const toHref = "/sensors";

    const [page, setPage] = useState(0);
    const item = sensors[page];
    const title = "Sensors";
    function nextPage () {
        setPage(Math.min(page+1, sensors.length-1));
    }
    function previousPage (){
        setPage(Math.max(0, page-1));
    };
    const Stack = useBreakpointValue({
        base: VStack,
        md: HStack,
    });
    const ImgSize = useBreakpointValue({
        base: "250px",
        md: "290px",
    });
    return (<VStack w="100%" p={{base: 2, sm: 0}} pl={{sm: 5}}  boxSizing="border-box">
        <VStack alignItems="flex-start" userSelect={"none"} w="100%">
            <Heading textAlign={"left"}>{title}</Heading>
            <Img src={TitleUnderScore} maxW="300px"/>
        </VStack>
        <Stack justify={"center"} w="100%" flexWrap="wrap">
            <HStack flexWrap="wrap" justifyContent="center" p={2} spacing={{base: "1em", sm: "2em"}}>
                <VStack>
                    (<HStack>
                        <Img src={page <= 0 ? LeftArrow : LeftColArrow} onClick={previousPage} h={{base: "2em", sm: "3em"}} userSelect="none" />
                        <Heading whiteSpace="nowrap" size={{base: "xl", sm: "3xl"}} fontWeight="light" p={2}>{item?.title}</Heading>
                        <Img src={page >= sensors.length-1 ? RightArrow : RightColArrow} onClick={nextPage} h={{base: "2em", sm: "3em"}} userSelect="none" />
                    </HStack>)
                    <Flex
                        textAlign="center"
                        m={0}
                        display="flex"
                        justifyContent="center" alignItems="center"
                        rowStart={5} as={Text}  rowSpan={2}
                        textColor={colors.textBlue2} 
                        onClick={() => navigate(toHref, {state: page})} userSelect={"none"} 
                        fontSize={{base: "16px", sm: "24px"}}
                        fontWeight={"bold"}
                        cursor={"pointer"}>
                        Read More
                    </Flex>
                </VStack>
                <Flex 
                    border="4px solid black"
                    borderRadius="100%"
                    borderColor={`${colors.neonblue} ${colors.neonblue} ${colors.bgblue1} ${colors.bgblue1}`}
                    _before={{
                        transform: "rotate(0deg)",
                    }}
                    _after={{
                        transform: "rotate(-45deg)",
                    }}
                    maxW="fit-content"
                    maxH="fit-content"
                    userSelect={"none"}>
                    <MyImg src={baseURL + "assets/" + item?.path} minW={ImgSize} minH={ImgSize} h={ImgSize} w={ImgSize} 
                        objectFit="cover"
                        p={1}
                        onfs={true}
                        borderRadius="100%"
                    />
                </Flex> 
            </HStack>
        </Stack>
    </VStack>)
}
