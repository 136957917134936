import { HStack, Heading, Img, Text, VStack, useBreakpointValue, } from "@chakra-ui/react";

import LeftArrow from "../../assets/arrow_gray_left.svg";
import RightArrow from "../../assets/arrow_gray_right.svg";
import LeftColArrow from "../../assets/arrow_colored_left.svg";
import RightColArrow from "../../assets/arrow_colored_right.svg";
import TitleUnderScore from "../../assets/title_underscore.svg";
import * as colors from "../../data/colors";
import { MyImg } from "../myimg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { baseURL, useGetDataQuery } from "../../api/astrosApi";

export const ProjectsMP = () => {
    const {data=[]} = useGetDataQuery({resource: "projects"});
    const projects = data.filter(p => p.visible).sort((p1, p2) => p1.priority-p2.priority);
    const [page, setPage] = useState(0);
    const item = projects[page];
    const title = "Projects";
    const navigate = useNavigate();
    const toHref = "/work";

    const ImgSize = useBreakpointValue({
        base: "250px",
        md: "290px",
    });

    function nextPage () {
        setPage(Math.min(page+1, projects.length-1));
    }
    function previousPage (){
        setPage(Math.max(0, page-1));
    }
    if(!item) return null;
    return (<VStack w="100%" p={{base: 2, sm: 0}} pl={{sm: 5}} boxSizing="border-box">
        <VStack alignItems="flex-start" userSelect={"none"} w="100%">
            <Heading textAlign={"left"}>{title}</Heading>
            <Img src={TitleUnderScore} maxW="300px"/>
        </VStack>
        <HStack justify={"space-between"} flexWrap="wrap" justifyContent={"center"} spacing={{base: "0.5em", sm: "1em"}}>
            <VStack justifyContent={"space-between"} maxW={{base: "100%", md: "49%"}} alignItems={"flex-start"} >
                <Text fontSize="20px" fontWeight="bold" wordBreak={"break-word"}>
                    {item.title}
                </Text>
                <Text fontSize={"20px"} fontWeight="light" textAlign={"justify"}>
                    {item.title2}
                </Text>
                <Text 
                    textAlign="center"
                    m={0}
                    display="flex"
                    w="100%"
                    justifyContent="center" alignItems="center"
                    textColor={colors.textBlue2} 
                    onClick={()=> navigate(toHref, {state: page})} userSelect={"none"} 
                    fontSize="24px"
                    fontWeight={"bold"}
                    cursor={"pointer"}>
                        Read more
                </Text>
            </VStack>
            <HStack userSelect={"none"}>
                <Img src={page <= 0 ? LeftArrow : LeftColArrow} onClick={previousPage} h={{base: "2em", sm: "3em"}} userSelect="none" />
                <MyImg src={baseURL + "assets/" + item.path} minW={ImgSize} minH={ImgSize} h={ImgSize} w={ImgSize} objectFit="contain" p={1} onfs={true} />
                <Img src={page >= projects.length-1 ? RightArrow : RightColArrow} onClick={nextPage} h={{base: "2em", sm: "3em"}} userSelect="none" />
                </HStack>
            </HStack>
    </VStack>)
}