import { Delimiter } from "../../components";

import { Heading, VStack } from "@chakra-ui/react";

export const WorkNumber = ({ number, ...props }) => {
    return (
        <VStack pl={2} pr={2} {...props} pb="1em">
            <Heading size="3xl">{number.value}</Heading>
            <Delimiter width="1px" color="white" length="8em" mt={"1em"} mb="1em" />
            <Heading textAlign="center" fontSize="md">{number.title}</Heading>
        </VStack>
    )
}