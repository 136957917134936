import { VStack } from "@chakra-ui/react";
import { Sensors } from "../../components";
import * as colors from "../../data/colors";

import earthBG from "../../assets/earth_2024.jpg";

export const SensorsPage = (props) => {
    return (<VStack 
    width="100%" overflow="auto"
    color={colors.textBlue}
    backgroundRepeat="no-repeat" backgroundPosition={"-400px"} backgroundImage={earthBG} bgColor={"white"} backgroundSize={"contain"}
    flexGrow={1} {...props}>
        <Sensors />
    </VStack>)
}