import { WorkNumbers } from "./worknumbers";
import { HStack, VStack, useBreakpointValue } from "@chakra-ui/react";
import * as colors from "../../data/colors";
import { useGetDataQuery } from "../../api/astrosApi";

const workNumbers = [
    {title: "Finished projects", value: 1},
    {title: "Ongoing projects", value: 1},
    {title: "Employees", value: 1},
];

const networkNumbers = [
    {title: "Operational", value: 1},
    {title: "Validation", value: 1},
    {title: "Commissioning", value: 1},
];

export const WorkNumberParent = () => {
    const { data } = useGetDataQuery({resource: "company"});

    if(data) {
        data.find(x => x.id === "worknumbers").value.split(' ').forEach((num, i) => workNumbers[i].value = num);
        data.find(x => x.id === "sensornetwork").value.split(' ').forEach((num, i) => networkNumbers[i].value = num);
    }

    const Stack = useBreakpointValue({
        base: VStack,
        sm: VStack,
        lg: HStack,
    })
    return (<Stack align="flex-start" w="100%" p={2} bg={colors.bgblue1} justify={"space-evenly"} alignItems="center" pt={12}>
        <WorkNumbers title="Our work" numbers={workNumbers} />
        <WorkNumbers title="Sensor Network" numbers={networkNumbers} />
    </Stack>)
}