import { WorkNumber } from "../";
import * as colors from "../../data/colors";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

export const WorkNumbers = ({ title, numbers }) => {
    return (<VStack w="fit-content" bg={colors.bgblue1} p={2} position="relative" pb={9}>
        <Box 
            position="absolute" border={`1px solid ${colors.neonblue}`}
            mt={"1em"}
            ml={"5em"}
            mr={"5em"}
            w="calc(100% - 10em)" h="calc(100% - 2em)" boxSizing="border-box"
            p={"3em"}
            zIndex={0}
            opacity={{base: 0, sm: 100}}
        />       
        <Text
            p={0} m={0} zIndex={0}
            pl={"1.25em"} pr={"1.25em"}
            textAlign="center" 
            fontSize="2em"
            fontWeight={"bold"}
            lineHeight={"1em"}
            mb={10}
            bg={colors.bgblue1}>
                <nobr>{title}</nobr>
        </Text>
        <HStack spacing={16} flexWrap="wrap" justify="center">
            {numbers.map((number) => (<WorkNumber key={number.title} number={number} bg={colors.bgblue1} zIndex={0} />))}
        </HStack>
    </VStack>)
}