import { HStack } from "@chakra-ui/react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminLogin, AdminNavbar, EmbbedForm } from "../../components";
import {
    AdminImagesPage,
    AdminPartnersPage,
    AdminProjectsPage,
    AdminSensorsPage,
    AdminCompanyPage,
    AdminServicesPage,
} from "./";
import { useEffect, useState } from "react";
import { EmailsPage } from "./emailsPage";

export const AdminPage = () => {
    const [logged, setlogged] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!logged) {
            navigate("./login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged]);

    return (
        <HStack w="100%" h="100%" overflow="auto">
            {!logged || <AdminNavbar />}
            <Routes>
                <Route
                    path="/login"
                    element={
                        <AdminLogin loginsuccess={() => setlogged(true)} />
                    }
                />
                <Route path="/images" element={<AdminImagesPage />} />
                <Route path="/partners" element={<AdminPartnersPage />} />
                <Route path="/embbed" element={<EmbbedForm />} />
                <Route path="/projects" element={<AdminProjectsPage />} />
                <Route path="/services" element={<AdminServicesPage />} />
                <Route path="/sensors" element={<AdminSensorsPage />} />
                <Route path="/settings" element={<AdminCompanyPage />} />
                <Route path="/emails" element={<EmailsPage />} />
            </Routes>
        </HStack>
    );
};
