import { Heading, Img, VStack } from "@chakra-ui/react";
import TitleUnderScore from "../../assets/title_underscore.svg";
import HtmlToReact from "html-to-react";
import { useState } from "react";
import { useGetDataQuery } from "../../api/astrosApi";

export const Socials = ({ title = "News feed" }) => {
    const [parser] = useState(new HtmlToReact.Parser());

    const { data: embbed } = useGetDataQuery({ resource: "embbed" });

    const preview = parser.parse(embbed || "<b>. . .</b>");

    return (
        <VStack
            bg="white"
            borderRadius="10px"
            w="100%"
            p={{ base: 2, sm: 0 }}
            pl={{ sm: 5 }}
        >
            <VStack alignItems="flex-start" userSelect={"none"} w="100%">
                <Heading textAlign={"left"}>{title}</Heading>
                <Img src={TitleUnderScore} maxW="300px" />
            </VStack>
            {preview}
        </VStack>
    );
};
