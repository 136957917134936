import { VStack } from "@chakra-ui/react";
import { MyNavbar, MyFooter } from "./components";
import { MyRouter } from "./router";

function App() {
    return (<VStack width="100%" height="100%" justify="space-between" rowGap={0}>
        <MyRouter>
            <MyNavbar />
        </MyRouter>
        <MyFooter />
    </VStack>
    );
}

export default App;
