import { Button, FormControl, FormErrorMessage, Input, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../api/astrosApi";

export const AdminLogin = ({loginsuccess}) => {
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [login, {isLoading}] = useLoginMutation();
    const [error, setError] = useState("");
    function onSubmit(e) {
        e.preventDefault();
        login(password).unwrap()
        .then((_payload) => { 
            loginsuccess();
            navigate("../");
        })
        .catch(({data}) => setError(data.reason));
    }
    return (<VStack w="100%" h="100%" justify={"center"}>
        <VStack onSubmit={onSubmit} as={"form"} spacing={2}>
            <FormControl w="fit-content" isInvalid={error}>
                <Input type="password"  value={password} onChange={({target: {value}}) => {setPassword(value); setError("");}} placeholder="password" required={true}/>
                <FormErrorMessage>
                    {error}
                </FormErrorMessage>
            </FormControl>
            {isLoading ? "SPINNER" : (<Button type="submit" w="100%">Login</Button>)}
        </VStack>
    </VStack>)
}