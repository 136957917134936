import { Box } from "@chakra-ui/react";

export const Delimiter = ({length, width, vertical=false, color, ...props}) => {
    const L = vertical ? "h" : "w";
    return (<Box        
        {...({
            [L]: length, 
            [`min${L.toUpperCase()}`]: length,
        })}
        border={`${width} solid ${color}`}
        {...props}
    />)
}