import { useEffect, useState } from "react";
import { useGetDataQuery, useUpdateResourceMutation } from "../../api/astrosApi";
import { DataTableControl } from "../../components/adminzone/dataTableControl";
import { Button, Checkbox, FormControl, FormLabel, HStack, Heading, Input, Select, Textarea, VStack } from "@chakra-ui/react";

export const AdminServicesPage = () => {
    const {data: services=[]} = useGetDataQuery({resource: "services"});

    return (
        <DataTableControl 
            columns={["id", "title", "bodytext", "image", "visible"]} 
            tableName={"services"} 
            data={services} 
            EditForm={EditForm} 
        />
    )
}

const EditForm = ({datarow, cancel}) => {
    const initState = {
        title: "",
        bodytext: "",
        image: "",
        visible: false,
    }
    const {data: images=[]} = useGetDataQuery({resource: "images"});
    const [updateResource, { isLoading }] = useUpdateResourceMutation();
    const [formState, setFormState] = useState({});

    useEffect(() => {
        if(datarow) {
            setFormState(datarow)
        } else {
            setFormState(initState);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datarow]);

    async function onSubmit(e) {
        e.preventDefault();

        await updateResource({
            resource: "services",
            ...formState,
        })

        setFormState(initState);
        cancel();
    }

    function handleChange({target: {name, value}}) {
        setFormState({...formState, [name]: value});
    }

    return (<VStack as="form" w="100%" alignItems={"flex-start"} justify="center" p={5} onSubmit={onSubmit}>
        <HStack justify={"space-between"} w="100%">
            <Heading size="sm">{datarow ? `Editing #${datarow.id}` : "Creating new"}</Heading>
            <HStack>
                <Button size="sm" type="submit">{datarow ? "Save" : "Create"}</Button>
                {datarow ? <Button size="sm" onClick={cancel}>Cancel</Button> : null}
            </HStack>
        </HStack>
            <Input placeholder="title #1" isDisabled={isLoading} variant="filled" required={true} size="sm" onChange={handleChange} value={formState.title} name="title"/>
            <Textarea maxLength={1000} placeholder="body text" isDisabled={isLoading} minH="200px" variant="filled" required={true} size="sm" onChange={handleChange} value={formState.bodytext} name="bodytext" />

            <Select isDisabled={isLoading} variant="filled" size="sm" required={true} placeholder='Select image' value={formState.image} onChange={handleChange} name="image">
                {images?.slice()?.sort(image=>image.name)?.map(image => (
                    <option key={image?.id} value={image.id}>#{image?.id} {image.name}</option>
                ))}
            </Select>
            <HStack as={FormControl} align={"center"} alignContent={"center"}>
                <FormLabel m={0}>Visible</FormLabel>
                <Checkbox isDisabled={isLoading} size="md" isChecked={formState.visible} onChange={({target: { checked }}) => setFormState({...formState, visible: checked})} name="visible" />
            </HStack>
    </VStack>)
}