import { Button,  Heading, VStack } from "@chakra-ui/react";
import { MdEmail } from "react-icons/md";
import * as colors from "../../data/colors";
import { SocialsIcons } from "../socials";
import { useGetDataQuery } from "../../api/astrosApi";

export const ContactBox = (props) => {
    const {data=[]} = useGetDataQuery({resource: "company"});
    const mail = data.find(r => r.id === "email")?.value || "office@astros.eu";
    return (<VStack {...props} justify={"center"} align={"center"} p={10}>
        <Heading size={{base: "2xl", sm: "3xl", md: "xl", lg: "3xl"}}><nobr>Get in touch</nobr></Heading>        
            <Button
                size="lg"
                height="48px"
                width="200px"
                variant="ghost"
                color={"white"}
                _hover={{ border: `2px solid ${colors.neonblue}` }}
                leftIcon={<MdEmail color={colors.neonblue} size="20px" />}>
            <a href={`mailto:${mail}`}>{ mail }</a>
            </Button>
            <SocialsIcons transparentBg={true}/>
    </VStack>)
}