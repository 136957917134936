import { HStack, IconButton } from "@chakra-ui/react";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";

export const SocialsIcons = ({size="xs", transparentBg=false}) => {
    const sizes = {
      xs: "20px",
      md: "34px"
    }
    const links = [
      {label: "linked", url:"https://www.linkedin.com/company/astros-solutions/" , icon: BsLinkedin},
      {label: "instagram", url:"https://www.instagram.com/astrossolutions/" , icon: BsInstagram},
      {label: "twitter", url:"https://twitter.com/AstrosSolutions" , icon: BsTwitter},
    ]
    return (<HStack
        spacing={5}
        px={5}
        justifyContent="center"
      >
        {links.map(link => {
          const Icon = link.icon;
          return (<a href={link.url} target="_blank" rel="noopener noreferrer">
          <IconButton
            aria-label={link.label + "-icon"}
            background={transparentBg ? "none" : "rgb(46, 78, 123)"}
            size={size}
            // isRound={true}
            _hover={{ bg: "#0D74FF" }}
            icon={<Icon size={sizes[size]} />}
          /></a>)})}
      </HStack>)
}