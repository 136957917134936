import { MyMap, BannerForm, Addressbox, ContactBox } from "../../components";
import { Flex, HStack, VStack } from "@chakra-ui/react";
import * as colors from "../../data/colors";

export const ContactPage = (props) => {
    const officeLocation = {
        lat: 48.150843,
        lng: 17.068879,
    };
    return (
        <VStack
            width="100%"
            flexGrow={1}
            overflow="auto"
            {...props}
            spacing={0}
        >
            <BannerForm logoAsHeading={true} showText={false} />
            <HStack minHeight={"33vw"} w="100%" flexWrap={"wrap"} spacing={0}>
                <Flex
                    flexGrow={1}
                    minW="33%"
                    w={{ base: "100%", md: "33%" }}
                    height={{ base: "100vw", md: "100%" }}
                >
                    <MyMap
                        center={officeLocation}
                        height="100%"
                        maxZoom={18}
                        zoom={12}
                    />
                </Flex>
                <Addressbox
                    w={{ base: "100%", md: "33%" }}
                    minW="33%"
                    height={{ base: "100vw", md: "100%" }}
                />
                <ContactBox
                    w={{ base: "100%", md: "33%" }}
                    minW="33%"
                    height={{ base: "100vw", md: "100%" }}
                    bg={colors.bgblue1}
                    color="White"
                />
            </HStack>
        </VStack>
    );
};
